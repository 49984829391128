import { classes } from "@r8er/lib/utils";
import { InputHTMLAttributes, useEffect, useState } from "react";
import PasswordStrengthBar, {
  PasswordFeedback,
} from "react-password-strength-bar";
import styles from "./Input.module.css";
import { OnInputChange, OnInputChangeCB } from "./onChange";

export interface InputPasswordProps
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    "onChange" | "defaultChecked" | "type"
  > {
  id: string;
  name: string;
  defaultValue?: InputHTMLAttributes<HTMLInputElement>["defaultValue"];

  showStrength?: boolean;

  onChange?: OnInputChangeCB;
}

export const InputPassword = ({
  id,
  name,
  defaultValue,
  onChange,
  showStrength,
  ...props
}: InputPasswordProps) => {
  const [value, setValue] = useState<string>((defaultValue as string) ?? "");
  const [feedback, setFeedback] = useState<PasswordFeedback>(null);
  const [score, setScore] = useState<number>(0);

  useEffect(() => {
    setValue((defaultValue as string) ?? "");
  }, [defaultValue]);

  return (
    <>
      <input
        {...props}
        type="password"
        id={id}
        value={value}
        name={name}
        onChange={OnInputChange(onChange, value, setValue)}
        className={classes(styles.input, props?.className)}
        style={{
          marginBottom: 0,
        }}
      />
      {showStrength ? (
        <>
          <input
            type="hidden"
            name="password-strength"
            id="password-strength"
            value={score}
          />
          <input
            type="hidden"
            name="password-feedback-warning"
            id="password-feedback-warning"
            value={`${feedback?.warning ?? ""}`}
          />
          <input
            type="hidden"
            name="password-feedback-suggestions"
            id="password-feedback-suggestions"
            value={`${(feedback?.suggestions ?? []).join("\n")}`}
          />
          <PasswordStrengthBar
            onChangeScore={(passwordScore, scoreFeedback) => {
              setScore(passwordScore);
              setFeedback(scoreFeedback);
            }}
            style={{
              height: "2.5rem",
              padding: "0.2rem 1.6rem",
            }}
            password={value}
          />
        </>
      ) : null}
    </>
  );
};
