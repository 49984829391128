import {
  ChangeEvent,
  ChangeEventHandler,
  Dispatch,
  SetStateAction,
} from "react";

export type OnInputChangeCB = <T, K>(value: {
  newValue: T;
  oldValue: T;
  event: ChangeEvent<K>;
}) => void;

const isInput = (v): v is HTMLInputElement => "type" in v;

export const OnInputChange =
  <T, K extends HTMLInputElement | HTMLTextAreaElement>(
    onChangeCB: OnInputChangeCB,
    value: T,
    setValue: Dispatch<SetStateAction<T>>,
    transformer: (v: T) => T = (v) => v
  ): ChangeEventHandler<K> =>
  (event) => {
    const oldValue = transformer(value as unknown as T);
    const newValue = transformer(
      (isInput(event.target) && event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value) as unknown as T
    );
    setValue(newValue);
    if (onChangeCB) onChangeCB({ oldValue, newValue, event });
  };
