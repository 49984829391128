import { RefAttributes } from "react";
import VerificationInput, {
  VerificationInputProps,
} from "react-verification-input";
import style from "./Input.module.css";

export const InputCode = (
  props: VerificationInputProps & RefAttributes<HTMLInputElement>
) => {
  return (
    <div className={style.codeContainer}>
      <VerificationInput
        {...props}
        inputProps={{
          className: style.inputCodeField,
          ...props.inputProps,
        }}
        containerProps={{
          className: style.inputCode,
        }}
        classNames={{
          character: style.inputCharacter,
          characterInactive: style.inputCharacter,
          characterSelected: style.inputCharacter,
        }}
      />
    </div>
  );
};
